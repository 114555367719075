import type { Asset, Entry } from 'contentful'

import { HeroPrimary } from '@cbhq/cdx-components'

import type { CDXSettingsSiteFields } from '../../globals//siteSettings'
import { useContentfulMicrocopy } from '../../globals/microcopy'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'
import type { CDXSettingsProjectFields } from '../../globals/projectSettings'
import type { TemplateLayoutProps } from '../PageTemplateLayout'
import { PageTemplateLayout } from '../PageTemplateLayout'
import type { PageTemplateProvidersProps } from '../PageTemplateProviders'
import { PageTemplateProviders } from '../PageTemplateProviders'

export const ERROR_TEMPLATE_CONTENT_MODEL_ID = 'cdxTemplateError'

// TODO: Remove <backToUrl> and <microcopies> and use <backToAction> instead once all error pages migrated to cdx-template-error
export type ErrorTemplateFields = {
  errorHeadline: string
  errorDescription: string
  errorImage?: Asset
  projectConfig: Entry<CDXSettingsProjectFields>
  backToAction?: Entry<CDXGlobalNavLinkFields>
}

export type ErrorTemplateExtraProps = {
  siteConfig: Entry<CDXSettingsSiteFields>
  fallbackImage?: string
  backToUrl?: string
}

export const ErrorTemplate = ({
  errorHeadline,
  errorDescription,
  errorImage,
  projectConfig,
  backToAction,

  siteConfig,
  fallbackImage,
  backToUrl = '/',

  path,
  onSearch,
  templateWidth,

  ...pageTemplateProviderProps
}: ErrorTemplateFields &
  ErrorTemplateExtraProps &
  Omit<PageTemplateProvidersProps, 'componentMap'> &
  TemplateLayoutProps) => {
  return (
    <PageTemplateProviders
      siteConfig={siteConfig}
      {...pageTemplateProviderProps}
    >
      <PageTemplateLayout
        siteConfig={siteConfig}
        projectConfig={projectConfig}
        path={path}
        templateWidth={templateWidth}
      >
        <Content
          errorHeadline={errorHeadline}
          errorDescription={errorDescription}
          errorImage={errorImage}
          fallbackImage={fallbackImage}
          backToUrl={backToUrl}
          backToAction={backToAction}
        />
      </PageTemplateLayout>
    </PageTemplateProviders>
  )
}

const Content = ({
  errorHeadline,
  errorDescription,
  errorImage,
  fallbackImage,
  backToUrl,
  backToAction,
}: Partial<ErrorTemplateFields & ErrorTemplateExtraProps>) => {
  const microcopies = useContentfulMicrocopy()
  const linkProps = backToAction ? mapEntryLink(backToAction) : undefined

  return (
    <HeroPrimary
      headline={errorHeadline || ''}
      layout="left"
      excerpt={errorDescription}
      mediaAlt={errorImage?.fields.title || 'error'}
      mediaUrl={errorImage?.fields.file.url || fallbackImage}
      ctaButtonLabel={linkProps?.label || microcopies.global?.goBackToCoinbase}
      ctaButtonUrl={linkProps?.url || backToUrl}
      imageFit="contain"
    />
  )
}
