import { getAllContentEntries } from './client'
import type { EntryProps } from './types'

export const fetchSlugs = async (
  templateType?: string,
  queryOpts?: {
    env?: string
    isPreview?: boolean
    tagId?: string
    locale?: string
  },
): Promise<string[]> => {
  const entries = (await getAllContentEntries({
    env: queryOpts?.env,
    isPreview: queryOpts?.isPreview,
    tagId: queryOpts?.tagId,
    locale: queryOpts?.locale,
    queryParams: {
      ...(templateType && {
        'fields.content.sys.contentType.sys.id': templateType,
      }),
      select: 'fields.slug',
      include: 0,
    },
  })) as unknown as EntryProps

  return (entries?.items || []).map((entry) => entry.fields.slug)
}
