import type { Entry } from 'contentful'

import { getContentEntries } from './client'
import type { EntryProps, ContentfulQueryOptions } from './types'
import type { CDXGlobalTagFields } from '../globals/tag'
import { EDITORIAL_TEMPLATE_CONTENT_MODEL_ID } from '../templates/Editorial'

export const fetchArticlesMatchingTerm = async (
  term: string | undefined,
  productTag: string,
  tags: Entry<CDXGlobalTagFields> | Entry<CDXGlobalTagFields>[] | undefined,
  queryOpts: ContentfulQueryOptions,
  limit?: number,
  skip?: number,
  order: 'asc' | 'desc' = 'asc',
): Promise<EntryProps> => {
  const defaultValue = { items: [], total: 0 }

  if (!term) return defaultValue

  const tagId = Array.isArray(tags)
    ? tags.map((tag) => tag.sys.id).join(',')
    : tags?.sys.id

  const relatedArticle = (await getContentEntries({
    env: queryOpts.env ?? undefined,
    isPreview: queryOpts.isPreview ?? undefined,
    locale: queryOpts.locale ?? undefined,
    queryParams: {
      // When matching linked entry fields, it is necessary to provided linked item contentType
      // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/search-on-references/search-on-references/console/js
      'fields.content.sys.contentType.sys.id':
        EDITORIAL_TEMPLATE_CONTENT_MODEL_ID,
      'metadata.tags.sys.id[all]': productTag,
      'fields.content.fields.headline[match]': `"${term}"`,
      'fields.content.fields.tags.sys.id[in]': tagId,
      order:
        order === 'asc' ? '-fields.publicationDate' : 'fields.publicationDate',
      limit,
      skip,
    },
  })) as unknown as EntryProps

  return relatedArticle || defaultValue
}
