import {
  Image,
  ImageFullWidth,
  SectionComponentLayout,
} from '@cbhq/cdx-components'

import type { CDXGlobalMediaFields } from '../../globals/media'

type InnerProps = {
  isFullWidth?: boolean
  hasSpacingTop?: boolean
  options?: Record<string, string>
}

export const GlobalMedia = ({
  media,
  altText,
  isFullWidth = true,
  hasSpacingTop = true,
  options,
}: CDXGlobalMediaFields & InnerProps) => (
  <SectionComponentLayout
    hasSpacingHorizontal={false}
    hasSpacingTop={
      options?.hasSpacingTop !== undefined
        ? Boolean(options?.hasSpacingTop)
        : hasSpacingTop
    }
  >
    {options?.isFullWidth !== undefined ? (
      options?.isFullWidth
    ) : isFullWidth ? (
      <ImageFullWidth src={media?.fields?.file?.url} altText={altText} />
    ) : (
      <Image src={media?.fields?.file?.url} alt={altText} />
    )}
  </SectionComponentLayout>
)
