import type { Document, TopLevelBlockEnum } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'

export const stringToRichTextDocument = (
  text: string = '',
  nodeType: TopLevelBlockEnum = BLOCKS.PARAGRAPH,
): Document => ({
  content: [
    {
      content: [
        {
          data: {},
          marks: [],
          nodeType: 'text',
          value: text,
        },
      ],
      data: {},
      nodeType: nodeType,
    },
  ],
  data: {},
  nodeType: BLOCKS.DOCUMENT,
})
