import type { Document } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'

export const stringToRichTextListDocument = (
  text: string,
): Document | undefined => {
  if (!text) return undefined

  const parts = text
    .replace(/-/g, '')
    .trim()
    .split('\n')
    .filter((part) => !!part)

  return {
    content: [
      {
        content: parts.map((part) => ({
          content: [
            {
              content: [
                {
                  data: {},
                  marks: [],
                  nodeType: 'text',
                  value: part,
                },
              ],
              data: {},
              nodeType: BLOCKS.PARAGRAPH,
            },
          ],
          data: {},
          nodeType: BLOCKS.LIST_ITEM,
        })),
        data: {},
        nodeType: BLOCKS.UL_LIST,
      },
    ],
    data: {},
    nodeType: BLOCKS.DOCUMENT,
  }
}
