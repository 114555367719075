import { getContentEntries } from './client'
import type { EntryProps } from './types'

/**
 * Fetch all articles for given queryOpts, skipping current slug article.
 */
export const fetchContents = async (
  queryOpts: {
    env?: string
    isPreview?: boolean
    slug?: string
    tagId?: string
    contentType?: string
    limit?: number
    skip?: number
    locale?: string
  },
  order: 'asc' | 'desc' = 'asc',
): Promise<EntryProps> => {
  const defaultValue = { items: [], total: 0 }
  const entries = (await getContentEntries({
    isPreview: queryOpts.isPreview,
    env: queryOpts.env,
    locale: queryOpts.locale,

    tagId: queryOpts.tagId,
    slug: queryOpts.slug,

    queryParams: {
      order:
        order === 'asc' ? '-fields.publicationDate' : 'fields.publicationDate',
      'fields.content.sys.contentType.sys.id': queryOpts.contentType,
      limit: queryOpts.limit,
      skip: queryOpts.limit,
    },
  })) as unknown as EntryProps

  return entries || defaultValue
}
