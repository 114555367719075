import type { Entry } from 'contentful'

import { Link } from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'

export const GlobalNavLink = (linkProps: CDXGlobalNavLinkFields) => {
  const { url, label } = mapEntryLink({
    fields: linkProps,
  } as Entry<CDXGlobalNavLinkFields>)

  return <Link href={url}>{label}</Link>
}
