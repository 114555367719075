import { filterLinkFields } from './filterLinkFields'
import type { ComposePageEntry } from '../globals/composePage'
import type { PageTemplateFields } from '../globals/pageTemplateTypes'

/**
 * Filters SubNav link's fields in Project Settings and sorts out unnecessary data
 */

export const filterSubNavLinkFields = (
  composePage: ComposePageEntry<PageTemplateFields>,
) => ({
  ...composePage,
  fields: {
    ...composePage.fields,
    content: {
      ...composePage.fields.content,
      fields: {
        ...composePage.fields.content.fields,
        projectSettings: {
          ...composePage.fields.content.fields?.projectSettings,
          fields: {
            ...composePage.fields.content.fields?.projectSettings?.fields,
            subNavLinks: (
              composePage.fields.content.fields?.projectSettings?.fields
                ?.subNavLinks || []
            ).map((navlink) => filterLinkFields(navlink)),
          },
        },
      },
    },
  },
})
